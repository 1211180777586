/*////////////////////////////////////////////////////////////////////
////////// Portfolio Banner/ Header //////////////////////////////////
////////////////////////////////////////////////////////////////////*/
.portfolio-banner {
    background-image: linear-gradient(
      to bottom, rgba(255,255,255,.25),
                 rgba(255,255,255,.5),
                 rgba(255,255,255,.25));
    color: black; 
    height: 100vh;
    margin: auto;
    position: -webkit-sticky; 
    position: sticky; 
    text-align: center;
    text-shadow: -5px 15px 5px rgb(82, 80, 80);
    top: 0;  
    word-spacing: -25px;
    z-index: -200;
    @media (max-width: 550px) {
      text-shadow: -5px 5px 5px rgb(82, 80, 80);} }
/*////////////////////////////////////////////////////////////////////
////////// Portfolio Card Container and Adjustments //////////////////
////////////////////////////////////////////////////////////////////*/
  .portfolio-container {
    min-height: 100vh;
    .card {
      h2:nth-child(2) {
        margin-top: -5%;}
      h3:nth-child(6) { 
        text-align: center;}
      .fontawesome {
        color: white;
        font-size: 2rem; 
        margin-bottom: -.5rem;}
      .devicon {
        fill: white;
        filter: drop-shadow(-5px 15px 5px black);
        margin-bottom: -.5rem;
        margin-left: .25rem;
        margin-right: .25rem;
        max-width: 2rem;
        opacity: 1;}}}