/*////////////////////////////////////////////////////////////////////
////////// Tech Stack Banner/ Header /////////////////////////////////
////////////////////////////////////////////////////////////////////*/
.tech-stack-banner {
    bottom: 0; 
    margin: auto;
    max-width: 85vw;
    padding-bottom: 50vh;
    padding-top: 5vw;
    position: -webkit-sticky;
    position: sticky; 
    text-align: center;
    text-shadow: -5px 15px 5px black;
    white-space: nowrap;
    z-index: 200; 
    @media (max-width: 550px) {
      max-width: 95vw;} }
/*////////////////////////////////////////////////////////////////////
////////// Panel and Icon Adjustments ////////////////////////////////
////////////////////////////////////////////////////////////////////*/    
  .tech-stack {
    display: flex;
    flex-flow: wrap;
    height: 150vw;
    justify-content: center;
    margin: auto;
    padding-bottom: 10vw;
    position: relative;
    width: 90vw;
    z-index: -200;}
  .devicon { 
    fill: white;
    filter: drop-shadow(-5px 15px 5px black);
    opacity: .65;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 5vh;
    max-width: 15vw;}